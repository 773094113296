import React, {useEffect, useRef, useState} from "react";
import IsScrolling from 'react-is-scrolling';
import useChat from "../../hooks/useChat";
import {v4 as uuidv4} from 'uuid';
import config from "../../config"
import Reactions from "../reactions/Reactions";
import Player from "../../containers/Player";
import UserInterface from "../userInterface/UserInterface";
import Watermark from "../watermark/Watermark";
import moment from 'moment'
import {
    CustomView
} from "react-device-detect";
//import {Conference} from "../conference";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from "lightgallery/plugins/video";
import LightGallery from "lightgallery/react";
import {translations} from "../../assets/translations";
import VimeoPlayer from "../../containers/VimeoPlayer";


const getCookie = (name) => {
    function escape(s) {
        return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
    }

    let match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}

const Live = (props) => {
    const [reactions, setReactions] = useState({
        channel_1: [],
        channel_2: [],
        channel_3: [],
        channel_4: [],
    })

    const [isFullScreen, setIsFullScreen] = useState(props.playerOnly)
    const [volume, setVolume] = useState(/*getCookie("dc-player-volume") > 0 ? getCookie("dc-player-volume") : */0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [totalDonations, setTotalDonations] = useState(0)
    const shouldStickScroll = useRef(true)

    const {
        messages,
        sendMessage,
        joinRoom,
        sendReaction,
        fetchingMessages,
        rowVirtualizer,
        messageListRef
    } = useChat(props.socketIOWebsocket, props.userToken, props.displayName, {
        "reaction": (payload) => {
            if (config.REACTION_ALLOWED_EMOJIS.indexOf(payload.data) >= 0) {
                handleNewReaction(payload)
            }
        },
        "event.updated": (payload) => {
            props.setEvent(payload)
        }
    }, props.historyUrl, shouldStickScroll, props.isScrolling)

    /**
     * Custom handle new reaction method
     * @param payload
     */
    const handleNewReaction = (payload) => {
        setReactions(oldReactions => {
            let newReactions = oldReactions;
            let reactionsLengths = Object.keys(oldReactions).map(channel => oldReactions[channel].length)
            let currentReactionCount = reactionsLengths.reduce((a, b) => a + b, 0);
            let leastOccupiedChannel = Object.keys(oldReactions)[reactionsLengths.indexOf(Math.min(...reactionsLengths))];
            let randomSize = Math.random() * (config.REACTION_MAX_SCALE_FACTOR - config.REACTION_MIN_SCALE_FACTOR) + config.REACTION_MIN_SCALE_FACTOR;

            if (currentReactionCount <= config.REACTION_INFLIGHT_LIMIT) {
                let newItemUUID = uuidv4()
                newReactions = {
                    ...oldReactions, [leastOccupiedChannel]: [...oldReactions[leastOccupiedChannel], {
                        data: payload.data,
                        uuid: newItemUUID,
                        size: randomSize,
                    }]
                }

                /**
                 * Removes the element we've just added
                 *
                 * TODO :
                 * If you send 5 messages at the same time, when they all times out, one message is displayed again
                 */
                setTimeout(() => {
                    setReactions((oldReactions2) => ({
                        ...oldReactions2,
                        [leastOccupiedChannel]: oldReactions[leastOccupiedChannel].filter((item) => item.uuid !== newItemUUID)
                    }));
                }, 10000)
            }

            return newReactions;
        })
    }

    useEffect(() => {
        initChat()
        // initTimeChecks()
    }, [])

    useEffect(() => {
        setTotalDonations(Math.floor(props.event.total_donations / 100))
    }, [props.event.total_donations])

    useEffect(() => {
        if (isFullScreen) {
            window.scrollTo(0, 0);
            document.querySelector('body').classList.add('no-scroll')
            window.dispatchEvent(new Event('resize'))
        } else {
            document.querySelector('body').classList.remove('no-scroll')
            window.dispatchEvent(new Event('resize'))
        }
    }, [isFullScreen])

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen)
        // window.resizeTo(100,100)
    }
    const initChat = () => {
        joinRoom(props.roomName)
    }
    const buildCoffeeBreakLink = (url, selectedLanguage) => {
        if (selectedLanguage) {
            const params = new URL(window.location.href).searchParams;
            const language = params.get('language');

            if (params && language) {
                url = url + "?language=" + language
            }
        }

        return url;
    }
    return (
        <>
            {(props.event.status === "live" || props.event.status === "coffee_break" || props.event.status === "replay") &&
            <>
                {!props.event.replay_mode
                    ? <div className={`videopage mb-50 mb-lg-0 ${isFullScreen ? "fullscreen" : ""}`}>
                        <div className="bg-black">
                            <div className="hero-container">
                                <div id="chat-player"
                                     className={`${isFullScreen ? "fullscreen " : ""} ${props.event.status === "replay" ? 'replay' : 'live'}`}>
                                    <div className={'position-relative player-container'}>
                                        {props.event.enable_watermark == '1' &&
                                        <Watermark displayName={props.username}/>}
                                        {!props.event.is_coffee_break && <Reactions
                                            reactions={reactions}
                                            isMobileOnly={props.isMobileOnly}
                                        />}

                                        {props.playerScript.indexOf('vimeo') > -1 && <VimeoPlayer
                                            event={props.event}
                                            playerScript={props.playerScript}
                                            volume={volume}
                                            isPlaying={isPlaying}
                                            setIsPlaying={setIsPlaying}
                                            setVolume={setVolume}
                                        />}
                                        {props.playerScript.indexOf('vimeo') === -1 && <Player
                                            event={props.event}
                                            playerScript={props.playerScript}
                                            volume={volume}
                                            isPlaying={isPlaying}
                                            setIsPlaying={setIsPlaying}
                                            setVolume={setVolume}
                                        />}
                                    </div>
                                    <UserInterface
                                        sendMessage={sendMessage}
                                        sendReaction={sendReaction}
                                        playerOnly={props.playerOnly}
                                        messages={messages}
                                        activeLocales={props.activeLocales}
                                        messageListRef={messageListRef}
                                        rowVirtualizer={rowVirtualizer}
                                        fetchingMessages={fetchingMessages}
                                        displayName={props.displayName}
                                        roomName={props.roomName}
                                        toggleFullScreen={toggleFullScreen}
                                        isFullScreen={isFullScreen}
                                        event={props.event}
                                        networkDiagnostics={props.networkDiagnostics}
                                        subscriptionStatus={props.subscriptionStatus}
                                        selectedLanguage={props.selectedLanguage}
                                        setSelectedLanguage={props.setSelectedLanguage}
                                        volume={volume}
                                        setVolume={setVolume}
                                        isPlaying={isPlaying}
                                        setIsPlaying={setIsPlaying}
                                        isMobileOnly={props.isMobileOnly}
                                    />
                                    <div className={"chat-shadow" + (messages.length > 0 ? " active" : "")}></div>
                                </div>
                            </div>
                            {props.event.status === "coffee_break" &&
                            <div className="coffee-break-picker">
                                <div className="container-fluid pl-60 pr-60">
                                    <ul>
                                        <li className="coffee-break-item text" style={{animationDelay: 500 + "ms"}}>
                                            <h2>Choose your break!</h2>
                                        </li>
                                        {props.event.events.map((item, index) => (
                                            <li className="coffee-break-item workshop" key={index}
                                                style={{animationDelay: ((index + 1) * 200 + 500) + "ms"}}>
                                                <a href={buildCoffeeBreakLink(item.url, props.selectedLanguage)}
                                                   className="w-100">
                                                    <div className="over">
                                                        <p dangerouslySetInnerHTML={{__html: item.name_coffee_break}}/>
                                                        <button
                                                            className="btn-ui-icon btn-coffeebreak-launcher mdi mdi-arrow-right"></button>
                                                    </div>
                                                    <img src={item.thumbnail}/>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                    : <div className="banner"></div>
                }
                {!props.playerOnly && <>
                    <div className="container">
                        <div className="row">
                        <div className="col-xl-12">
                            <h2 className={'text-center mt-lg-50 mb-30'}>{translations[props.selectedLanguage].event_title}</h2>

                            {props.event.description_while_event[props.selectedLanguage] && <div id="event-description"
                                                                                                 className="content px-0 px-md-50 px-lg-0 mt-lg-10 text-center text-lg-left">
                                <div
                                    dangerouslySetInnerHTML={{__html: props.event.description_while_event[props.selectedLanguage]}}/>
                            </div>}

                            <h3 className={'text-center text-danger mt-50'}>{translations[props.selectedLanguage].event_subtitle}</h3>

                            <div className={'row my-30'}>
                                <div className={'col-lg-4'}>
                                    <table className={'table category-table'}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.ladies.link']}>
                                                    {translations[props.selectedLanguage]['category.ladies']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.ladies_complications.link']}>
                                                    {translations[props.selectedLanguage]['category.ladies_complications']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.mens.link']}>
                                                    {translations[props.selectedLanguage]['category.mens']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.mens_complications.link']}>
                                                    {translations[props.selectedLanguage]['category.mens_complications']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.iconic.link']}>
                                                    {translations[props.selectedLanguage]['category.iconic']}
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-lg-4'}>
                                    <table className={'table category-table'}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.tourbillon.link']}>
                                                    {translations[props.selectedLanguage]['category.tourbillon']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.calendar_astronomy.link']}>
                                                    {translations[props.selectedLanguage]['category.calendar_astronomy']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.mechanical_exception.link']}>
                                                    {translations[props.selectedLanguage]['category.mechanical_exception']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.chronograph.link']}>
                                                    {translations[props.selectedLanguage]['category.chronograph']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.divers.link']}>
                                                    {translations[props.selectedLanguage]['category.divers']}
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div className={'col-lg-4'}>
                                    <table className={'table category-table'}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.jewellery.link']}>
                                                    {translations[props.selectedLanguage]['category.jewellery']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.artistic_crafts.link']}>
                                                    {translations[props.selectedLanguage]['category.artistic_crafts']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.petite_aiguille.link']}>
                                                    {translations[props.selectedLanguage]['category.petite_aiguille']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.challenge.link']}>
                                                    {translations[props.selectedLanguage]['category.challenge']}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={translations[props.selectedLanguage]['category.mechanical_clock.link']}>
                                                    {translations[props.selectedLanguage]['category.mechanical_clock']}
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className={'container'}>
                    <div className={'social-networks bg-black'}>
                            <div className={'row'}>
                                <div className={'col-lg-8 offset-lg-2'}>
                                    <p className={'text-center'}>
                                        {translations[props.selectedLanguage].follow_us}
                                    </p>

                                    <div className={'d-flex justify-content-center'}>
                                        <a href={'https://www.facebook.com/GrandPrixHorlogerieGeneve/'} target={'_blank'}
                                           className={'social-networks-item p-4'}>
                                            <img src={'/images/facebook.svg'}/>
                                        </a>
                                        <a href={'https://twitter.com/fondationgphg'} target={'_blank'}
                                           className={'social-networks-item p-4 mt-1'}>
                                            <img src={'/images/twitter.svg'}/>
                                        </a>
                                        <a href={'https://instagram.com/gphg_official/'} target={'_blank'}
                                           className={'social-networks-item p-4'}>
                                            <img src={'/images/instagram.svg'}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'container'}>
                    <div className="footer small px-20 py-50 px-md-50 px-lg-0 mx-lg-20 text-center text-lg-left">
                            <div className={'row'}>
                                <div className={'col-lg-6'}>
                                    <span
                                        dangerouslySetInnerHTML={{__html: translations[props.selectedLanguage].copyright}}></span>
                                </div>
                                <div className={'col-lg-6 text-center text-lg-right'}>
                                    <span
                                        dangerouslySetInnerHTML={{__html: translations[props.selectedLanguage].visit_gphg}}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>}
            </>}
        </>
    )
}
export default IsScrolling(Live);
