import React, {useState, useEffect} from "react";
import Chat from "../../containers/Chat";
import Questions from "../questions/Questions";
import config from "../../config";
import LanguageSwitcher from "../language/LanguageSwitcher";
import VolumeSlider from "../volumeSlider/VolumeSlider";
import coffeeBrekImg from "../../assets/icon-coffeebreak.svg"
import VolumeButtonOnly from "../volumeButtonOnly/VolumeButtonOnly";
import {usePopperTooltip} from "react-popper-tooltip";
import LiveQuestion from "../liveQuestion/LiveQuestion";
import {translations} from "../../assets/translations";

const UserInterface = (props) => {
    const [interfaceHidden, setInterfaceHidden] = useState(false)

    // when question composer is open, the interface should not hide
    const [isInterfaceActive, setIsInterfaceActive] = useState(false)
    // when mouse is over interface, the interface should not hide
    const [mouseIsOverInterface, setMouseIsOverInterface] = useState(false)
    // when we open the volume slider we hide the language picker to avoid graphical glitch
    const [volumeIsOpen, setVolumeIsOpen] = useState(false)
    const [showTooltip, setShowTooltip] = useState(true)

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        defaultVisible: true,
        visible: showTooltip,
        closeOnOutsideClick: false,
        placement: 'top',
        onVisibleChange: (state) => {
            if (!state) {
                setShowTooltip(false)
            }
        }
    });

    let mouseMoveTimer = null;

    const mouseMoveCallback = () => {
        clearMouseMoveTimeout()
        setInterfaceHidden(false)
        startMouseMoveTimeout()
    }

    useEffect(() => {
        document.addEventListener("mousemove", mouseMoveCallback);
        startMouseMoveTimeout()
        return () => {
            if (mouseMoveTimer) {
                clearTimeout(mouseMoveTimer);
            }

            document.removeEventListener('mousemove', mouseMoveCallback)
        }
    }, [])

    /**
     *
     */
    const clearMouseMoveTimeout = () => {
        if (mouseMoveTimer) {
            clearTimeout(mouseMoveTimer);
            mouseMoveTimer = null;
        }
    }
    /**
     *
     */
    const startMouseMoveTimeout = () => {
        if (mouseMoveTimer) {
            clearInterval(mouseMoveTimer);
        }
        mouseMoveTimer = setTimeout(() => {
            setInterfaceHidden(true)

        }, config.MOUSE_MOVE_DELAY_HIDE_INTERFACE);
    }

    const getAvailableLanguages = () => {
        return Object.keys(props.event.stream_script).filter((row) => !!props.event.stream_script[row] && props.activeLocales.indexOf(row) >= 0)
    }

    return (
        <>
            <div className="user-interface__wrapper"
                 onMouseEnter={() => setMouseIsOverInterface(true)}
                 onMouseLeave={() => setMouseIsOverInterface(false)}>
                <div className="user-interface">
                    {props.event.status === "live" && <>
                        <div
                            className={"ui-wrapper text-left" + ((interfaceHidden && !isInterfaceActive && !mouseIsOverInterface) ? ' hide' : '')}>

                            {showTooltip && (
                                <div
                                    ref={setTooltipRef}
                                    {...getTooltipProps({className: 'tooltip-container'})}
                                >
                                    <div {...getArrowProps({className: 'tooltip-arrow'})} />
                                    <p className="text-dark">
                                        {translations[props.selectedLanguage].make_sure_you_enable}<br/>
                                        {translations[props.selectedLanguage].sound_by_clicking_here}
                                    </p>
                                </div>
                            )}

                            <ul className="ui-controls">
                                {!props.playerOnly && <li>
                                    <button
                                        className={"btn-ui-icon btn-control-fullscreen mdi " + (props.isFullScreen ? " mdi-fullscreen-exit active" : " mdi-fullscreen")}
                                        onClickCapture={props.toggleFullScreen}/>
                                </li>}
                                <li>
                                    <VolumeButtonOnly
                                        className="volume-slider-wrapper"
                                        volume={props.volume}
                                        setVolume={props.setVolume}
                                        setTriggerRef={setTriggerRef}
                                        setShowTooltip={setShowTooltip}
                                    />
                                    {/*<VolumeSlider
                                            className="volume-slider-wrapper"
                                            volume={props.volume}
                                            setVolume={props.setVolume}
                                            setVolumeIsOpen={setVolumeIsOpen}
                                        />*/}
                                </li>
                                {!!props.playerOnly && getAvailableLanguages().length > 1 &&
                                <li>
                                    <div className={volumeIsOpen ? 'hide' : ''}>
                                        <LanguageSwitcher
                                            languages={getAvailableLanguages()}
                                            selectedLanguage={props.selectedLanguage}
                                            setSelectedLanguage={props.setSelectedLanguage}
                                        />
                                    </div>
                                </li>
                                }
                            </ul>
                        </div>
                        {!props.event.is_coffee_break && !!props.event.enable_qa_button && <div
                            className={"ui-wrapper text-center" + ((interfaceHidden && !isInterfaceActive && !mouseIsOverInterface) ? ' hide' : '')}>
                            <LiveQuestion
                                displayName={props.displayName}
                                networkDiagnostics={props.networkDiagnostics}
                                event={props.event}
                                onJoin={() => {
                                    // When the user joins the live Q&A, mute the video to avoid double sound
                                    props.setVolume(0)
                                    setShowTooltip(true)
                                }}
                                subscriptionStatus={props.subscriptionStatus}
                                setIsInterfaceActive={setIsInterfaceActive}/>
                        </div>}
                        {!props.event.is_coffee_break && props.event.enable_chat == '1' && <div className="ui-wrapper">
                            <Chat sendMessage={props.sendMessage}
                                sendReaction={props.sendReaction}
                                history={props.event.enable_chat_history}
                                messages={props.messages}
                                messageListRef={props.messageListRef}
                                rowVirtualizer={props.rowVirtualizer}
                                fetchingMessages={props.fetchingMessages}
                                roomName={props.roomName}
                                isMobileOnly={props.isMobileOnly}
                            />
                        </div>}
                    </>}
                </div>
                <div className="interface-gradient"
                     style={{backgroundImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAFKCAYAAAGr+gUEAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAAaADAAQAAAABAAABSgAAAAA+lQU5AAAAy0lEQVQ4EcWS2woCQQxD63r9/y925whzpGQEQdyXkjRpJ12tqjpte7mMchvlOkvqqYqSr/WgTCzfQLjPGAk9ZjRUgm+jBzqDdvY6xidN+jfEMemidBY+4nLM20XjGHscCAVJ7SX0lc8v6SoX+O4BPr6QCUDLHulRkyXNMsFZNf9S0FjGL4PQxqAUtiTqvuY7fl9LkPJpWR7omGaRY/SS4AKQVLPoh/taoEZ9yEDmM5AIVSpqwpK2iaXvs9DUtlQK4koLPYWEmhm6HKsnuFYFvYYO4fIAAAAASUVORK5CYII=)"}}></div>
            </div>

        </>
    )
}

export default UserInterface;
