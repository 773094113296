import React, {useEffect} from "react"
import {translations} from "../../assets/translations";
const After = (props) => {
    useEffect(() => {
        document.querySelector('body').classList.add('before-after-page')
        return () => document.querySelector('body').classList.remove('before-after-page')
    })
    useEffect(() => {
        if (props.event.background_before_event) {
            document.querySelector(":root").style.setProperty("--before-backgroundimage", 'url(/storage/'+props.event.background_before_event+')');
        }
    }, [])
    return (
        <div className="">
            <div className="container">
                <div className="row">
                    <div className="col-12 before-container text-center">
                        <img className={'img-fluid'} src={'/images/home_transparent_' + props.selectedLanguage + '.png'} />

                        <div className={'row'}>
                            <div className={'col-lg-6 offset-lg-3'}>
                                <div className="after-content" dangerouslySetInnerHTML={{__html: props.event.description_after_event[props.selectedLanguage]}}></div>
                            </div>
                        </div>

                        <div className="footer small px-20 py-50 px-md-50 px-lg-0 mx-lg-20 text-center text-lg-left">
                            <div className={'row'}>
                                <div className={'col-6'}>
                                <span
                                    dangerouslySetInnerHTML={{__html: translations[props.selectedLanguage].copyright}}></span>
                                </div>
                                <div className={'col-6 text-right'}>
                                <span
                                    dangerouslySetInnerHTML={{__html: translations[props.selectedLanguage].visit_gphg}}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default After
